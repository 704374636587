$(function() {

    $(".categoriaMasTruekes").on("click", function() {

        let categoria_id = $(this).data('id_categoria');
        $(".select-categoria").val(categoria_id).change();

        var o = new Option("option text", categoria_id);
        // jquerify the DOM object 'o' so we can use the html method
        $(o).html("option text");
        $(".select-categoria").append(o);

        if (screen.width >= 992) {
            console.log('Form desktop');
            $("#formFilterDesktop").submit();
        } else {
            console.log('Form mobile');
            $("#formFilterMobile").submit();
        }

    })



    /* Preview al cargar imagen */
    $(".custom-file-input").on("change", function() {
        var fileName = $(this).val().split("\\").pop();
        $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
        filePreview(this);
    });

    function filePreview(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.readAsDataURL(input.files[0]);
            reader.onload = function(e) {
                $('.content-img img').remove();
                $('.content-img').html('<div style="height: 40vh;"><img class="img-fluid" src="' + e.target.result + '" alt="Card image cap" style="object-fit: cover; height: 100%; width: 100%;"></div>');
            }
        }
    }


    /* Cargar subcategorias de las categorias con ajax */
    $('.select-categoria').on('change', function() {

        traerSubcategorias($(this).val());
    });

    function traerSubcategorias($categoria) {
        $.ajax({
            url: window.location.protocol + "//" + window.location.hostname + '/buscar-subcategorias',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
                categoria: $categoria
            },
            type: "POST",
            dataType: "json",
            success: function(data) {
                $(".select-subcategoria").html(data);
            }
        });
    }

    /* Muestra el valor de la distancia */
    $('.input-distancia').on('input', function() {
        $('.distancia-show-value').html($(this).val());
    });

    /* Funcion para mostrar el rango de precios */
    (function() {
        var parent = document.querySelector(".price-slider-escritorio");
        if (!parent) return;

        var rangeS = parent.querySelectorAll("input[type=range]"),
            numberS = parent.querySelectorAll("input[type=number]");

        rangeS.forEach(function(el) {
            el.oninput = function() {
                var slide1 = parseFloat(rangeS[0].value),
                    slide2 = parseFloat(rangeS[1].value);

                if (slide1 > slide2) {
                    [slide1, slide2] = [slide2, slide1];
                }

                numberS[0].value = slide1;
                numberS[1].value = slide2;
            }
        });

        numberS.forEach(function(el) {
            el.oninput = function() {
                var number1 = parseFloat(numberS[0].value),
                    number2 = parseFloat(numberS[1].value);

                if (number1 > number2) {
                    var tmp = number1;
                    numberS[0].value = number2;
                    numberS[1].value = tmp;
                }
                rangeS[0].value = number1;
                rangeS[1].value = number2;
            }
        });
    })();

    //Verion Movil
    (function() {
        var parent = document.querySelector(".price-slider-movil");
        if (!parent) return;

        var rangeS = parent.querySelectorAll("input[type=range]"),
            numberS = parent.querySelectorAll("input[type=number]");

        rangeS.forEach(function(el) {
            el.oninput = function() {
                var slide1 = parseFloat(rangeS[0].value),
                    slide2 = parseFloat(rangeS[1].value);

                if (slide1 > slide2) {
                    [slide1, slide2] = [slide2, slide1];
                }

                numberS[0].value = slide1;
                numberS[1].value = slide2;
            }
        });

        numberS.forEach(function(el) {
            el.oninput = function() {
                var number1 = parseFloat(numberS[0].value),
                    number2 = parseFloat(numberS[1].value);

                if (number1 > number2) {
                    var tmp = number1;
                    numberS[0].value = number2;
                    numberS[1].value = tmp;
                }
                rangeS[0].value = number1;
                rangeS[1].value = number2;
            }
        });
    })();




    $(".btn-add-image").on("click", function() {
        $('#img_banner_type').val($(this).attr("data-img-banner-type"));
        $('.content-img img').attr('src', $(this).parents(".jumbotron").find("img").attr("src"));
    });

    $(".btn-edit-image").on("click", function() {

        let id = $(this).attr("data-img-banner-id");
        let banner_type = $(this).attr("data-img-banner-type");
        $('#img_banner_type_edit').val(banner_type);
        $('#img_banner_id_edit').val(id);
        $('.content-img img').attr('src', $(this).parents(".jumbotron").find("img").attr("src"));

        datos = {
            id: id,
            banner_type: banner_type,
        }
        $.ajax({
            url: window.location.protocol + "//" + window.location.hostname + '/get-img-banner-edit',
            data: datos,
            type: "GET",
            dataType: "json",
            success: function(data) {
                //console.log(data);
                let url = window.location.protocol + "//" + window.location.hostname + '/storage/banners/' + data['nombre_imagen'];
                $('#bannerSliderUpDateModal img').attr('src', url);
                $('#titulo_edit').val(data['titulo']);
                $('#descripcion_edit').val(data['descripcion']);
                $('#redireccion_edit').val(data['redireccion']);
            }
        });
    });

    $('#myModalErrors').modal('toggle')

    $(".btnMe").on("click", function() {
        $(".textTam").css("display", "none");
    });

    $(".btnTr").on("click", function() {
        $(".textTam").css("display", "none");
    });

    $(".btnReg").on("click", function() {
        $(".textTam").css("display", "block");
    });

    $(".btnCa").on("click", function() {
        $(".textTam").css("display", "block");
    });
});